<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card class="my-card m-0" @scroll.passive="handleScroll">
          <b-table
            striped
            small
            id="myTables"
            bordered
            selectable
            selected-variant="primary"
            select-mode="single"
            responsive="sm"
            :items="displayedData"
            :fields="fields"
            head-row-variant="secondary"
            @row-clicked="clicked"
            @row-contextmenu="handleContextMenuEvent"
            @row-dblclicked="editForm">
            <template #cell(checkbox)="row">
              <b-form-checkbox v-model="row.detailsShowing" plain class="vs-checkbox-con" @change="row.toggleDetails">
              </b-form-checkbox>
            </template>
            <template #cell(polygon)="data">
              {{ data.item.polygon ? "Да" : "Нет" }}
            </template>
            <template #cell(latlng)="data">
              {{ data.item.lat ? [data.item.lat + "," + data.item.lng] : "Нет" }}
            </template>
          </b-table>
          <vue-context ref="menu">
            <li>
              <b-link class="d-flex align-items-center" @click="$emit('createVillages')">
                <feather-icon icon="PlusIcon" size="16" />
                <span class="ml-75">Добавить</span>
              </b-link>
            </li>
          </vue-context>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import VueContext from "vue-context";
import { cellSelect } from "@/utils/TableFunctions/cellSelect";
import lazyLoading from "@/@core/mixins/utils/lazyLoading";
export default {
  components: {
    VueContext,
  },
  mixins: [lazyLoading],
  props: ["dataTable"],
  data() {
    return {
      id: null,
      fields: [
        { key: "checkbox", label: "", thStyle: { width: "50px" } },
        { key: "id", label: "Id", sortable: true, thStyle: { width: "50px" } },
        {
          key: "name",
          label: "Населенный пункт",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "polygon",
          label: "Полигон",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "latlng",
          label: "Центр пункт населения",
          sortable: true,
          thStyle: { width: "100px" },
        },
        {
          key: "village_type_name",
          label: "Название деревни",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "city_name",
          label: "Название города",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "created_at",
          label: "Создан",
          sortable: true,
          thStyle: { width: "150px" },
        },
      ],
    };
  },
  methods: {
    clicked(item, index, event) {
      this.$store.state.filterPlus.tableIndex = index;
      cellSelect();
    },
    handleContextMenuEvent(item, index, event) {
      event.preventDefault();
      this.$refs.menu.open(event);
      this.id = item.id;
    },
    editForm(id) {
      this.$emit("editForm", id.id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/table.scss";
.my-card .card-body {
  padding: 0px;
}
.my-card {
  overflow: auto;
  height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card {
    overflow: auto;
    height: 530px;
  }
}
</style>
